<template lang="pug">
.Turn
  .ContainerHeader.pb-5
    .d-flex.align-items-center.justify-content-between
      img(src="@/assets/mobileFSFB/icn_backicn_back.png" alt="icn_backicn_back").backButton.icn_backicn_back.opacity-0.mr-3
      h3.fontTitleHeader.m-0 Turno asignado
      img(src="@/assets/mobileFSFB/icn_backicn_back.png" alt="icn_backicn_back").icn_backicn_back.opacity-0.mr-3.backButton
  .ContainerData.pt-3.px-2
    h3.yourTurnLabelO.text-center {{ selectedArea ? selectedArea.label : "" }}
    ul.containerCards
      figure.containerImg
        .position-absolute.assignedTurn
          p.mb-0 Te hemos dado un
          h5(v-if="priority") Turno preferencial
          h5(v-else) Turno general
        img(src="@/assets/mobileFSFB/doctor.png" alt="doctor")

    div(
      v-if="stage === -1"
      style="text-align: center;"
    )
      div( v-if="errorCounter < 3")
        Loading
        h3 Conectando...
      span(style="width: 100%; text-align: center" v-else)
        button.btn.btn-info(
          @click="startTurn"
          style="font-style: normal; font-weight: 600; font-size: 25.9817px; line-height: 34px; text-align: center; color: #f9f9f9;"
        ) Reintentar
      h5.mt-3(
        v-if="errorCounter >= 3"
        style="font-style: normal; font-weight: 600; font-size: 25.9817px; line-height: 34px; text-align: center; color: #d3455b;"
      ) Error de conexión con servidor. Presiona el botón para reintentar..
      span(style="width: 100%; text-align: center" v-if="errorCounter >= 3")
        button.btn.btn-info(
          @click="onGoToCancelTurn"
          style="font-style: normal;font-weight: 400;font-size: 17.9817px;line-height: 15px;text-align: center;color: rgb(249 249 249);opacity: 0.8;"
        ) Cancelar

    .whiteCard.mx-auto(v-if="!turn && stage === 0" style="text-align: center;")
      img(:src="Conected" class="size_icon mx-auto" style="width: 70%;")
      h5 Estamos solicitando tu turno. Por favor espera.
    .whiteCard.mx-auto(v-if="turn && status == 'WAITING_TO_BE_CALLED'")
      h3.yourTurnLabel Tu turno asignado
      h2.yourTurnValue {{ myTurn }}
      h6.subTitle.subTitleBlue: strong.ml-1 {{service}}
      p.yourEstimatedLabel Tiempo estimado de espera
      .yourEstimatedValue.mx-auto.d-flex.align-items-center
        p.mb-1.pt-3.mt-1 {{ calculatedMinutes(turn.jsonDetails) }}
        p.m-0.pt-3: small Minuto{{ calculatedMinutes(turn.jsonDetails) === 1 ? "" : "s" }}
    .whiteCard.mx-auto(style="height: auto; min-height: 272px" v-if="turn && status == 'ANNOUNCED'")
      h3.yourTurnLabel Tu turno
      h2.yourTurnValue {{ myTurn }}
      h3.animated-box.yourTurnLabel ha sido llamado
      p.yourEstimatedLabel
        small Acércate al módulo
      .pb-3
        .yourEstimatedValue.mx-auto.d-flex.align-items-center(:class="moduleCalled ? moduleCalled.length >= 5 && 'moduleCalledMore5' : ''")
          p.m-0.pt-1: small Módulo
          p.mb-1.pt-3.mt-3
            | {{ moduleCalled }}
    p.yourEstimatedLabel.mb-0.mt-4(v-if="turn && status == 'WAITING_TO_BE_CALLED'") Mientras esperas mira nuestros
    p.yourEstimatedLabel.mb-4(v-if="turn && status == 'WAITING_TO_BE_CALLED'") videos informativos
    button(
      v-if="turn && status == 'WAITING_TO_BE_CALLED'"
      @click="() => goToView({ view: 'TikTokVideos' })"
    ).outlinedBthal.buttonBthal.mx-auto.d-block.mb-2.text-center
      span.d-block Ver videos
    .pb-5.pt-3
      a(
        v-if="turn && status == 'WAITING_TO_BE_CALLED'" 
        @click="onGoToCancelTurn").mt-2.mx-auto.d-block.text-center.linkStyles Cancelar turno
      a(variant="whitecolmedica" v-if="turn && status != 'WAITING_TO_BE_CALLED'").mt-5.pb-3.mx-auto.d-block.text-center.linkStyles Ya puedes cerrar esta ventana
</template>

<script>
import { mapActions, mapState } from "vuex";
import Conected from "@/assets/conected.gif";

export default {
  name: "CurrentUser",

  data: () => ({
    Conected: Conected,
    errorCounter: 0
  }),

  computed: {
    ...mapState({
      turn: state => state.turns.info,
      charging: state => state.mobileFSFB.charging,
      docInfo: state => state.mobileFSFB.docInfo,
      priority: state => state.mobileFSFB.priority,
      env: state => state.env,
      selectedAccionFSB: state => state.mobileFSFB.selectedAccion,
      menu: state => state.mobileFSFB.menu,
      embebedData: state => state.mobileFSFB.embebedData,
      citaActual: state => state.mobileFSFB.citaActual,
      selectedArea: state => state.mobileFSFB.selectedArea
    }),

    toSend() {
      return {
        ...this.docInfo,
        CodigoOficina: "1?area=admisiones_1"
      };
    },

    status() {
      return this.turn?.status;
    },

    actualTurn() {
      return this.turn?.jsonDetails?.actualTurn;
    },

    myTurn() {
      return this.turn?.jsonDetails?.turn;
    },

    infoOfTurn() {
      return {
        onlyGenerate: this.onlyGenerate,
        source: this.source,
        queueId:
          this.demoMode || !this.queueId
            ? this.env.VUE_APP_DEBMEDIA_DEFAULT_TRAMITE
            : this.queueId,
        branchId:
          this.demoMode || !this.branchId
            ? this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH
            : this.branchId,
        coorelation_key: this.coorelation_key,
        withVideocall: false,
        withAnalitycExtraFields: this.withAnalitycExtraFields,
        params: this.userInfo
      };
    },

    service() {
      
      let serv = this.menu.find(m => {
        if (m.actionCode == this.selectedAccionFSB.actionCode) {
          return m;
        }
        if (m.children) {
          return m.children.find(
            c => c.actionCode == this.selectedAccionFSB.actionCode
          );
        }
      });
      if (serv && serv.children)
        serv = serv.children.find(
          s => s.actionCode == this.selectedAccionFSB.actionCode
        );
      if(serv) return serv?.service || serv?.label;
      else return this.citaActual?.service?.detail;
    },

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    moduleCalled() {
      return  this.turn.module?.tv_name || this.turn.module?.worker_name?.replace("Modulo ", "") || this.turn.workerName;
    }
  },

  props: {
    hideVideo: {
      type: Boolean,
      default: false
    },
    waitVideoSource: {
      type: String
    },
    waitVideoPoster: {
      type: String
    },
    onlyGenerate: {
      type: Boolean,
      default: false
    },
    tramiteName: {
      type: String,
      required: true
    },
    coorelation_key: {
      type: String
    },
    userInfo: {
      required: true
    },
    branchId: {
      type: String
    },
    queueId: {
      type: String
    },
    source: {
      type: String,
      required: true
    },
    withAnalitycExtraFields: {
      type: Boolean,
      default: false
    },
    withVideocall: {
      type: Boolean,
      default: false
    }
  },

  created() {
    this.startTurn();
  },

  watch: {
    status(val) {
      this.checkStatus(val);
    }
  },

  methods: {
    ...mapActions({
      getTurn: "turns/getTurn",
      goToView: "mobileFSFB/goToView",
      authenticate: "mobileFSFB/authenticate",
      sendTracking: "mobileFSFB/handleTracking",
      mobileNotification: "mobileFSFB/mobileNotification",
      cleanTurn: "cleanTurn",
    }),

    async onContinue() {
      await this.authenticate(this.toSend);

      // tracking
      setTimeout(() => {
        let origin = "mobile_fsfb";
        let auth = this.error
          ? " autentitacion-no-cliente"
          : "autenticacion-cliente";
        let trackingData = {
          uuid: window.person.id,
          accion: auth,
          debmedia_turn_code: "",
          url_origen: window.location.href,
          origen: origin + this.currentSite?.type,
          mensajes: ""
        };

        this.sendTracking(trackingData);
      }, 2000);
    },

    async startTurn() {
      this.stage = 0;
      if (this.errorCounter >= 3) this.errorCounter = 0;
      return this.getTurn(this.infoOfTurn)
        .then(turnInfo => {
          if (this.onlyGenerate)
            this.$emit("generatedTurn", {
              turnInfo,
              generationInfo: this.infoOfTurn
            });
        })
        .catch(error => {
          this.errorCounter++;
          console.log("error: " + this.errorCounter);
          console.error("Cant get turn for turn component", error);
          if (this.errorCounter < 3) setTimeout(() => this.startTurn(), 3000);
          else {
            this.stage = -1;
            this.$forceUpdate();
          }

          // else this.$emit("errorRequestTurn");
        });
    },

    onGoToCancelTurn() {
      this.goToView({ view: "CancelTurn" });
    },

    calculatedMinutes(turn) {
      return Math.ceil(turn.averageWaitingTime);
    },

    async checkStatus(newStatus) {
      switch (newStatus) {
        case "ANNOUNCED":
          //  send FSFB notification
          this.mobileNotification({
            title: "Turno Fundación Santa Fe",
            body: `Tu turno ${this.turn?.jsonDetails?.actualTurn} ha sido llamado.`,
            type: 1
          });
          // localStorage.clear();
          // this.goToView({ view: "Home" });
          break;
        case "WAITING_TO_BE_CALLED":
          // this.showMeet = false;
          break;
        case "FINALIZED":
          localStorage.clear();
          this.cleanTurn();
          if (this.embebedData) {
            this.$router.replace({
              path: "/mobile_fsfb",
              query: { embebedData: this.embebedData }
            });
          }
          this.goToView({ view: "Finalize" });
          break;
        case "REVOKED":
          localStorage.clear();
          this.cleanTurn();
          if (this.embebedData) {
            this.$router.replace({
              path: "/mobile_fsfb",
              query: { embebedData: this.embebedData }
            });
          }
          this.goToView({ view: "Canceled" });
          break;
        case "CANCELED_BY_USER":
          localStorage.clear();
          this.cleanTurn();
          this.goToView({ view: "Canceled" });
          if (this.embebedData) {
            this.$router.replace({
              path: "/mobile_fsfb",
              query: { embebedData: this.embebedData }
            });
          }
          break;

        default:
          break;
      }
      return newStatus;
    }
  }
};
</script>

<style>
.ContainerHeader {
  background-color: var(--color-radiant-blue);
  margin-bottom: -30px;
}

.Turn {
  background-color: #f2f4f8;
  height: 100%;
}

.mobileFSFB {
  background-color: #f2f4f8;
}

.ContainerData {
  border-radius: 20px 20px 0 0;
  background-color: #f2f4f8;
  max-height: calc(100vh - 82px);
  overflow-y: auto;
}

.subTitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  margin: 0 23px;
  font-family: var(--font-primary);
  color: var(--color-radiant-blue);
}

.subTitleBlue {
  text-align: center;
  line-height: 1;
  margin-bottom: 15px;
}

.subTitle strong {
  font-weight: 800;
  font-family: var(--font-secondary);
}

.fontTitleHeader {
  font-family: var(--font-secondary);
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--color-white);
  padding-top: 18px;
}

.liCard {
  width: 325px;
  border-radius: 16px;
  list-style: none;
  box-shadow: 0px 1px 4px rgba(21, 21, 21, 0.02),
    0px 8px 12px rgba(21, 21, 21, 0.08);
  background: var(--color-white);
}

.buttonSelect {
  font-size: 13px;
  line-height: 18px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--color-white);
}

.fontLiCard {
  color: var(--color-gray);
}

.fontLiCard strong {
  font-family: var(--font-secondary);
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}

.containerImg {
  position: relative;
  display: flex;
  justify-content: center;
}

.assignedTurn {
  top: 50%;
  left: 15%;
  transform: translateY(-50%);
}

.assignedTurn p {
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.assignedTurn h5 {
  font-weight: 800;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */

  display: flex;
  align-items: center;

  /* Primary/white */

  color: #ffffff;
}

.whiteCard {
  width: 312px;
  /* height: 272px; */
  padding-bottom: 15px;
  border-radius: 16px;
  box-shadow: 0px 3.28295px 6.56589px rgba(21, 21, 21, 0.08);
  background: #ffffff;
}

.yourTurnLabel {
  font-family: var(--font-secondary);
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #788591;
  padding-top: 26.12px;
  margin-bottom: 12.12px;
}

.yourTurnValue {
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  color: #009dac;
}

.yourEstimatedLabel {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #788591;
}

.yourEstimatedValue {
  width: 103.44px;
  height: 103.91px;
  flex-direction: column;
  border-radius: 50%;
  background: #4b5cb7;
}

.yourEstimatedValue p {
  height: 10px;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 45px;
  text-align: center;
  color: var(--color-white);
}

.yourEstimatedValue p small {
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--color-white);
}

.linkStyles {
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  text-decoration-line: underline;
  color: #009de0;
}

.animated-box {
  -webkit-animation: fontbulger 2s infinite;
  -moz-animation: fontbulger 2s infinite;
  -o-animation: fontbulger 2s infinite;
  animation: fontbulger 2s infinite;
}

.moduleCalledMore5 {
  width: 100%;
  max-width: 70%;
  height: fit-content;
  padding: 15px;
  padding-top: 0;
  border-radius: 25px;
}

.moduleCalledMore5 p {
  height: fit-content;
  padding: 0 !important;
  margin: 0 !important;
}

@-webkit-keyframes fontbulger {
  0%,
  100% {
    font-size: 16px;
  }

  50% {
    font-size: 21px;
  }
}

@-moz-keyframes fontbulger {
  0%,
  100% {
    font-size: 16px;
  }

  50% {
    font-size: 21px;
  }
}

@-o-keyframes fontbulger {
  0%,
  100% {
    font-size: 16px;
  }

  50% {
    font-size: 21px;
  }
}

@keyframes fontbulger {
  0%,
  100% {
    font-size: 16px;
  }

  50% {
    font-size: 21px;
  }
}

.outlinedBthal {
  width: 267px;
  height: 48px;
  background: #ffffff;
  border: 2px solid #009dac;
  border-radius: 16px;
}

.outlinedBthal span {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #009dac;
}

.buttonBthal {
  background: #009dac;
  border: 2px solid #009dac;
  border-radius: 16px;
}

.buttonBthal span {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.backButton {
  padding-top: 21px;
}
</style>
